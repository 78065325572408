
<template>
  <div class="app-container" style="padding:0px;margin:0px;">
    <section>
      <el-container>
        <el-main>
          <el-col :span="6" style="border: 1px solid #eee;">
            <el-form :inline="true">
              <el-form-item style="margin-bottom:-10px;padding-left:5px;">
                <h3>国家/地区</h3>
              </el-form-item>
              <el-form-item style="margin-bottom:-9px;">
                <el-button size="small" type="text" @click="create(0,'Country',null)" style="line-height:55px">新增</el-button>
              </el-form-item>
            </el-form>
            <el-table :data="countrys" highlight-current-row @current-change="table1CurrentChange"    :header-cell-style="{
        'font-weight': '400',
        height: '40px',
        padding: '0',
        background: '#4a8bc1',
        color: 'white',
      }" ref="table1">
              <el-table-column prop="Code" label="编号" width="100"></el-table-column>
              <el-table-column prop="Name" label="名称" min-width="100"></el-table-column>
              <el-table-column label="操作" align="center" width="100">
                <template slot-scope="scope">
                  <el-button type="text" class="el-icon-edit" @click="update(scope.row)"></el-button>
                  <el-button type="text" class="el-icon-delete" @click="remove(scope.row)"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="6" style="border: 1px solid #eee;">
            <el-form :inline="true">
              <el-form-item style="margin-bottom:-10px;padding-left:5px;">
                <h3>省/州</h3>
              </el-form-item>
              <el-form-item style="margin-bottom:-9px;">
                <el-button size="small" type="text" @click="create(1,'Province',selectCountry)" style="line-height:55px">新增</el-button>
              </el-form-item>
            </el-form>
            <el-table style="width:100%" :data="provinces" highlight-current-row @current-change="table2CurrentChange"    :header-cell-style="{
        'font-weight': '400',
        height: '40px',
        padding: '0',
        background: '#4a8bc1',
        color: 'white',
      }" ref="table2">
              <el-table-column prop="Code" label="编号" width="100"></el-table-column>
              <el-table-column prop="Name" label="名称" min-width="120"></el-table-column>
              <el-table-column label="操作" width="100" align="center">
                <template slot-scope="scope">
                  <el-button type="text" class="el-icon-edit" @click="update(scope.row)"></el-button>
                  <el-button type="text" class="el-icon-delete" @click="remove(scope.row)"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="6" style="border: 1px solid #eee;">
            <el-form :inline="true">
              <el-form-item style="margin-bottom:-10px;padding-left:5px;">
                <h3>城市</h3>
              </el-form-item>
              <el-form-item style="margin-bottom:-9px;">
                <el-button size="small" type="text" @click="create(2,'City',selectProvince)" style="line-height:55px">新增</el-button>
              </el-form-item>
            </el-form>
            <el-table style="width:100%" :data="citys" highlight-current-row @current-change="table3CurrentChange"    :header-cell-style="{
        'font-weight': '400',
        height: '40px',
        padding: '0',
        background: '#4a8bc1',
        color: 'white',
      }" ref="table3">
              <el-table-column prop="Code" label="编号" width="100"></el-table-column>
              <el-table-column prop="Name" label="名称" min-width="120"></el-table-column>
              <el-table-column label="操作" width="100" align="center">
                <template slot-scope="scope">
                  <el-button type="text" class="el-icon-edit" @click="update(scope.row)"></el-button>
                  <el-button type="text" class="el-icon-delete" @click="remove(scope.row)"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="6" style="border: 1px solid #eee;">
            <el-form :inline="true">
              <el-form-item style="margin-bottom:-10px;padding-left:5px;">
                <h3>区县</h3>
              </el-form-item>
              <el-form-item style="margin-bottom:-9px;">
                <el-button size="small" type="text" @click="create(3,'County',selectCity)" style="line-height:55px">新增</el-button>
              </el-form-item>
            </el-form>
            <el-table style="width:100%" :data="countys" highlight-current-row @current-change="table4CurrentChange"    :header-cell-style="{
        'font-weight': '400',
        height: '40px',
        padding: '0',
        background: '#4a8bc1',
        color: 'white',
      }" ref="table4">
              <el-table-column prop="Code" label="编号" width="100"></el-table-column>
              <el-table-column prop="Name" label="名称" min-width="120"></el-table-column>
              <el-table-column label="操作" width="100" align="center">
                <template slot-scope="scope">
                  <el-button type="text" class="el-icon-edit" @click="update(scope.row)"></el-button>
                  <el-button type="text" class="el-icon-delete" @click="remove(scope.row)"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-main>
      </el-container>
      <div v-if="formVisible">
        <el-dialog v-dragDialog :title="textMap[dialogStatus]" :visible.sync="formVisible" width="450px" :close-on-click-modal="false">
          <el-form :model="addForm" ref="dataForm" label-width="110px" :rules="formRules">
            <el-form-item prop="Key" hidden></el-form-item>
            <el-form-item label="代码：" prop="Code">
              <el-col :span="19">
                <el-input v-model="addForm.Code" auto-complete="off" placeholder="请输入代码"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="名称：" prop="Name">
              <el-col :span="19">
                <el-input v-model="addForm.Name" auto-complete="off" placeholder="请输入名称"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="描述：" prop="Desc">
              <el-col :span="19">
                <el-input v-model="addForm.Desc" type="textarea" auto-complete="off" placeholder="请输入描述"></el-input>
              </el-col>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="formVisible = false">取消</el-button>
            <el-button v-if="dialogStatus=='create'" ref="createSubmit" type="primary" @click="createSubmit">提交</el-button>
            <el-button v-else type="primary" ref="updateSubmit" @click="updateSubmit">提交</el-button>
          </div>
        </el-dialog>
      </div>
    </section>
  </div>
</template>

<script>
import { getRegionByParent, SaveRegion, DeleteRegion } from "@/api/region";
export default {
  data () {
    return {
      countrys: [],
      provinces: [],
      citys: [],
      countys: [],
      addForm: {
        Code: "",
        Name: "",
        RegionType: "",
        Parent: "",
        Desc: ""
      },
      formRules: {
        Code: [{ required: true, message: "请输入代码", trigger: "blur" }],
        Name: [{ required: true, message: "请输入名称", trigger: "blur" }]
      },
      dialogStatus: "",
      formVisible: false,
      textMap: {
        create: "创建",
        update: "修改"
      },
      regionType: "",
      selectCountry: "",
      selectProvince: "",
      selectCity: "",
      selectCounty: "",
      regionParent: "",
      selectLevel: 0
    };
  },
  methods: {
    create (level, type, parent) {
      this.regionType = type;
      if (parent == "") {
        this.msg.errorMsg("请选择上级");
        return;
      }
      this.regionParent = parent;
      this.selectLevel = level;
      this.dialogStatus = "create";
      this.addForm.Key = "";
      this.addForm.Code = "";
      this.addForm.Name = "";
      this.addForm.Desc = "";
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    createSubmit () {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          let para = this.addForm;
          para.Parent = { Key: this.regionParent };
          para.RegionType = this.regionType;

          SaveRegion(para).then(response => {
            if (response) {
              this.formVisible = false;
              this.queryRegions(this.regionParent, this.selectLevel);
            }
          });
        }
      });
    },
    update (row) {
      this.addForm = JSON.parse(JSON.stringify(row));
      this.dialogStatus = "update";
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    updateSubmit () {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          let para = this.addForm;

          SaveRegion(para).then(response => {
            if (response) {
              this.formVisible = false;
              this.queryRegions(
                this.addForm.Parent.Key,
                this.addForm.RegionType
              );
            }
          });
        }
      });
    },
    remove (row) {
      this.$confirm("确认删除此区域及所有子区域吗？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let para = {
            Key: row.Key
          };
          DeleteRegion(para).then(response => {
            if (response) {
              this.formVisible = false;
              this.queryRegions(row.Parent.Key, row.RegionType);
            }
          });
        })
        .catch(() => { });
    },

    queryRegions (Key, level) {
      let para = {
        parent: Key,
        search: ""
      };
      getRegionByParent(para).then(response => {
        if (response) {
          if (level == 0) {
            this.countrys = response.data.Items;
            if (this.countrys.length > 0) {
              this.$nextTick(() => {
                this.$refs.table1.setCurrentRow(this.countrys[0]);
              });
            }
          }
          if (level == 1) {
            this.provinces = response.data.Items;
            if (this.provinces.length > 0) {
              this.$nextTick(() => {
                this.$refs.table2.setCurrentRow(this.provinces[0]);
              });
            }
          }
          if (level == 2) {
            this.citys = response.data.Items;
            if (this.citys.length > 0) {
              this.$nextTick(() => {
                this.$refs.table3.setCurrentRow(this.citys[0]);
              });
            }
          }
          if (level == 3) {
            this.countys = response.data.Items;
          }
        }
      });
    },
    table1CurrentChange (currentRow, oldCurrentRow) {
      this.citys = [];
      this.countys = [];
      this.provinces = [];
      this.selectProvince = "";
      this.selectCity = "";
      this.selectCounty = "";
      if (currentRow) {
        this.selectCountry = currentRow.Key;
        this.queryRegions(currentRow.Key, 1);
      }
    },
    table2CurrentChange (currentRow, oldCurrentRow) {
      this.citys = [];
      this.countys = [];
      this.selectCity = "";
      this.selectCounty = "";
      if (currentRow) {
        this.selectProvince = currentRow.Key;
        this.queryRegions(currentRow.Key, 2);
      }
    },
    table3CurrentChange (currentRow, oldCurrentRow) {
      this.countys = [];
      this.selectCounty = "";
      if (currentRow) {
        this.selectCity = currentRow.Key;
        this.queryRegions(currentRow.Key, 3);
      }
    },
    table4CurrentChange (currentRow, oldCurrentRow) {
      if (currentRow) {
        this.selectCounty = currentRow.Key;
        this.queryRegions(currentRow.Key, 4);
      }
    }
  },
  mounted () {
    this.queryRegions("", 0);
  }
};
</script>
<style>
.el-dialog {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.el-input--small .el-input__inner {
  height: 36px;
  line-height: 36px;
}
</style>
