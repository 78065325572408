<template>
    <div class="basedata-container">
      <el-tabs type="border-card">
        <el-tab-pane label="地区信息">
          <region ref="region" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </template>
  <script>
  import region from "@/components/region";
  export default {
    components: {
      region,
    },
    data() {
      return {};
    },
    methods: {},
    mounted() {},
  };
  </script>
  <style lang='scss' scoped>
  .basedata-container {
    padding: 10px;
  }
  </style>
  